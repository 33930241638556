// https://github.com/philipwalton/analyticsjs-boilerplate/blob/master/src/analytics/multiple-trackers.js
import config from './utils/config';

const { analyticsId } = config;

/* global gtag */

/**
 * Bump this when making backwards incompatible changes to the tracking
 * implementation. This allows you to create a segment or view filter
 * that isolates only data captured with the most recent tracking changes.
 */
const TRACKING_VERSION = '2';

/**
 * A default value for dimensions so unset values always are reported as
 * something. This is needed since Google Analytics will drop empty dimension
 * values in reports.
 */
const NULL_VALUE = '(not set)';

const custom_map = {
  dimension1: 'TRAKING_VERSION',
  dimension2: 'CLIENT_ID',
  dimension3: 'WINDOW_ID',
  dimension4: 'HIT_ID',
  dimension5: 'HIT_TIME',
  dimension6: 'HIT_TYPE',
  dimension7: 'HIT_SOURCE',
  dimension8: 'VISIBILITY_STATE',
  metric1: 'RESPONSE_END_TIME',
  metric2: 'DOM_LOAD_TIME',
  metric3: 'WINDOW_LOAD_TIME',
};

/**
 * Initializes all the analytics setup. Creates trackers and sets initial
 * values on the trackers.
 */
export const init = () => {
  // Initialize the command queue in case analytics.js hasn't loaded yet.
  // window.ga = window.ga || ((...args) => (ga.q = ga.q || []).push(args));
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    dataLayer.push(arguments);
  };

  createTracker();
  trackErrors();
  trackCustomDimensions();
  // sendInitialPageview();
  sendNavigationTimingMetrics();
};

export const pageChange = path => {
  gtag('config', analyticsId, {
    // transport_type: 'beacon',
    // custom_map,
    HIT_SOURCE: 'pageload',
    page_path: path,
  });
};

export const setUser = username => {
  gtag('config', analyticsId, {
    user_id: username,
  });
};

export const setProject = projectId => {
  gtag('config', analyticsId, {
    project_id: projectId,
  });
};

/**
 * Tracks a JavaScript error with optional fields object overrides.
 * This function is exported so it can be used in other parts of the codebase.
 * E.g.:
 *
 *    `fetch('/api.json').catch(trackError);`
 *
 * @param {(Error|Object)=} err
 * @param {Object=} fieldsObj
 */
export const trackError = (err = {}, fieldsObj = {}) => {
  gtag(
    'event',
    fieldsObj.name || err.name,
    Object.assign(
      {
        event_category: 'Error',
        event_label: `${err.message}\n${err.stack || '(no stack trace)'}`,
        non_interaction: true,
      },
      fieldsObj,
    ),
  );
};

/**
 * Creates the trackers and sets the default transport and tracking
 * version fields. In non-production environments it also logs hits.
 */
const createTracker = () => {
  gtag('js', new Date());

  // Ensures all hits are sent via `navigator.sendBeacon()`.
  gtag('config', analyticsId, {
    transport_type: 'beacon',
    custom_map,
    HIT_SOURCE: 'pageload',
  });
};

/**
 * Tracks any errors that may have occured on the page prior to analytics being
 * initialized, then adds an event handler to track future errors.
 */
const trackErrors = () => {
  // Errors that have occurred prior to this script running are stored on
  // `window.__e.q`, as specified in `index.html`.
  const loadErrorEvents = (window.__e && window.__e.q) || [];

  const trackErrorEvent = event => {
    // Use a different eventCategory for uncaught errors.
    const fieldsObj = { event_category: 'Uncaught Error' };

    // Some browsers don't have an error property, so we fake it.
    const err = event.error || {
      message: `${event.message} (${event.lineno}:${event.colno})`,
    };

    trackError(err, fieldsObj);
  };

  // Replay any stored load error events.
  for (const event of loadErrorEvents) {
    trackErrorEvent(event);
  }

  // Add a new listener to track event immediately.
  window.addEventListener('error', trackErrorEvent);
};
/**
 * Sets a default dimension value for all custom dimensions on all trackers.
 */
const trackCustomDimensions = () => {
  // Sets a default dimension value for all custom dimensions to ensure
  // that every dimension in every hit has *some* value. This is necessary
  // because Google Analytics will drop rows with empty dimension values
  // in your reports.
  // Object.keys(dimensions).forEach(key => {
  // ga('set', dimensions[key], NULL_VALUE);
  // });

  // Adds tracking of dimensions known at page load time.
  gtag('event', 'tracking_version', { TRACKING_VERSION });
  // gtag('event', 'client_id', { TRACKING_VERSION });
  gtag('event', 'window_id', { WINDOW_ID: uuid() });

  // [dimensions.CLIENT_ID]: tracker.get('clientId'),

  // Adds tracking to record each the type, time, uuid, and visibility state
  // of each hit immediately before it's sent.
  // ga(tracker => {
  //   const originalBuildHitTask = tracker.get('buildHitTask');
  //   tracker.set('buildHitTask', model => {
  //     const qt = model.get('queueTime') || 0;
  //     model.set(custom_map.HIT_TIME, String(new Date() - qt), true);
  //     model.set(custom_map.HIT_ID, uuid(), true);
  //     model.set(custom_map.HIT_TYPE, model.get('hitType'), true);
  //     model.set(custom_map.VISIBILITY_STATE, document.visibilityState, true);

  //     originalBuildHitTask(model);
  //   });
  // });
};

/**
 * Gets the DOM and window load times and sends them as custom metrics to
 * Google Analytics via an event hit.
 */
const sendNavigationTimingMetrics = () => {
  // Only track performance in supporting browsers.
  if (!(window.performance && window.performance.timing)) return;

  // If the window hasn't loaded, run this function after the `load` event.
  if (document.readyState != 'complete') {
    window.addEventListener('load', sendNavigationTimingMetrics);
    return;
  }

  const nt = performance.timing;
  const navStart = nt.navigationStart;

  const responseEnd = Math.round(nt.responseEnd - navStart);
  const domLoaded = Math.round(nt.domContentLoadedEventStart - navStart);
  const windowLoaded = Math.round(nt.loadEventStart - navStart);

  // In some edge cases browsers return very obviously incorrect NT values,
  // e.g. 0, negative, or future times. This validates values before sending.
  const allValuesAreValid = (...values) =>
    values.every(value => value > 0 && value < 6e6);

  if (allValuesAreValid(responseEnd, domLoaded, windowLoaded)) {
    gtag('send', 'track', {
      event_category: 'Navigation Timing',
      event_label: NULL_VALUE,
      non_interaction: true,
      RESPONSE_END_TIME: responseEnd,
      DOM_LOAD_TIME: domLoaded,
      WINDOW_LOAD_TIME: windowLoaded,
    });
  }
};

/**
 * Generates a UUID.
 * https://gist.github.com/jed/982883
 * @param {string|undefined=} a
 * @return {string}
 */
const uuid = function b(a) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
};
